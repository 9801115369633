import "notyf/notyf.min.css"; // for React, Vue and Svelte
import { UsersIcon } from "@heroicons/react/24/outline";

function CustomersBreadcrumb() {
  return (
    <nav className="flex ml-10 mt-5 h-10" aria-label="Breadcrumb">
      <ol
        role="list"
        className="flex space-x-4 rounded-md bg-white px-6 shadow"
      >
        <li className="flex">
          <div className="flex items-center">
            <a href="/Customers" className="text-gray-400 hover:text-gray-500">
              <span>Back</span>
            </a>
          </div>
        </li>
      </ol>
    </nav>
  );
}

export default CustomersBreadcrumb;


import { React, useState, useRef, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Topbar from "../../Components/Topbar";
import { sendGetRequest, sendPostRequest } from "../../Client";
import ReactLoading from "react-loading";
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
import Swal from "sweetalert2";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import CustomersBreadcrumb from "../../Components/CustomersBreadcrumb";
import { Link, useNavigate, useParams } from "react-router-dom";

function CustomerCarrierSettings() {
  const { customerID } = useParams();
  const { carrierID } = useParams();

  const [inputs, setInputs] = useState({});

  const navigate = useNavigate();

  // confirmation notifications
  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
  });

  const updatedNotyf = () => {
    notyf.success("Settings updated");
  };

  // loading services
  const { data: settings, isLoading } = useQuery({
    queryFn: () =>
      sendGetRequest(
        "customers/carriers/settings/" + customerID + "/" + carrierID,
        {}
      ),
    queryKey: ["settings"],
  });


  useEffect(() => {
    if (settings) {
      const settingsObj = {};
      settings.settings.map((setting) => {
        settingsObj[setting.settingID] = setting.settingValue;
      });
      setInputs(settingsObj);
    }
  }, [settings]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = () => {
    const requestBody = {
      settings: inputs,
    };

    const res = sendPostRequest(
      "customers/carriers/settings/update/" + customerID + "/" + carrierID,
      requestBody
    );

    updatedNotyf();

    navigate(`/customer/carriers/${customerID}`, {
      replace: true,
      state: { added: 1 },
    });
  };

  if (!isLoading) {
    return (
      <>
        <div className="flex flex-row w-full">
          <Navbar />

          <div className="flex flex-col w-4/5 bg-gray-100">
            <Topbar />

            <nav className="flex ml-10 mt-5 h-10" aria-label="Breadcrumb">
              <ol
                role="list"
                className="flex space-x-4 rounded-md bg-white px-6 shadow"
              >
                <li className="flex">
                  <div className="flex items-center">
                    <a
                      href={`/customer/carriers/${customerID}`}
                      className="text-gray-400 hover:text-gray-500"
                    >
                      <span>Back</span>
                    </a>
                  </div>
                </li>
              </ol>
            </nav>

            <div className="bg-white px-4 sm:px-6 lg:px-8 border border-gray-200 ml-10 mr-10 mt-10 mb-40">
              <form>
                <div className="space-y-12">
                  <div className="mt-10">
                    <div className="sm:flex sm:items-center">
                      <div className="sm:flex-auto">
                        <h1 className="text-2xl font-semibold leading-6 text-gray-900 mb-5">
                          Settings
                        </h1>
                      </div>
                    </div>

                    <div className="mt-8 flow-root mb-10">
                      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-medium text-gray-900 sm:pl-0"
                                >
                                  Setting
                                </th>

                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-medium text-gray-900 sm:pl-0"
                                >
                                  Value
                                </th>

                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-medium text-gray-900"
                                ></th>
                              </tr>
                            </thead>
                            {!isLoading ? (
                              settings?.settings?.length > 0 ? (
                                <tbody className="divide-y divide-gray-200">
                                  {settings.settings?.map((item) => (
                                    <>
                                      <tr key={item.settingID}>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-700 sm:pl-0">
                                          {item.settingName}
                                        </td>

                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-700 sm:pl-0">
                                          <input
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            type="text"
                                            name={item.settingID}
                                            id="id"
                                            onChange={handleChange}
                                            value={inputs[item.settingID]}
                                          ></input>
                                        </td>
                                      </tr>
                                    </>
                                  ))}
                                </tbody>
                              ) : (
                                <>
                                  <tbody className="divide-y divide-gray-200">
                                    <tr>
                                      <td
                                        colSpan="4"
                                        className="w-full text-center whitespace-nowrap px-3 py-4 text-sm text-gray-500 col-span-6"
                                      >
                                        -- No Settings found --
                                      </td>
                                    </tr>
                                  </tbody>
                                </>
                              )
                            ) : (
                              <>
                                <tbody className="divide-y divide-gray-200">
                                  <tr>
                                    <td
                                      colSpan="5"
                                      className="w-full text-center whitespace-nowrap px-3 py-4 text-sm text-gray-500 col-span-6"
                                    >
                                      <ReactLoading
                                        type={"spin"}
                                        color="#5046e5"
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </>
                            )}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-end gap-x-6 mb-5">
                  <button
                    type="submit"
                    className="flex items-center justify-center rounded-md border border-transparent bg-slate-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-slate-700"
                    onClick={() => handleSubmit()}
                  >
                    Update Settings
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CustomerCarrierSettings;

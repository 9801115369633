import { React } from "react";

export default function ShipRow(props) {
  return (
    <tr>
    
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <input
          type="text"
          name="weight"
          id="weight"
          autoComplete="weight"
          placeholder="Lbs"
          onChange={(e) =>
            props.setShipData({
              ...props.shipData,
              [props.id + "-weight"]: e.target.value,
            })
          }
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <input
          type="text"
          name="dim1"
          id="dim1"
          autoComplete="dim1"
          placeholder="inch"
          onChange={(e) =>
            props.setShipData({
              ...props.shipData,
              [props.id + "-dim1"]: e.target.value,
            })
          }
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <input
          type="text"
          name="dim2"
          id="dim2"
          autoComplete="dim2"
          placeholder="inch"
          onChange={(e) =>
            props.setShipData({
              ...props.shipData,
              [props.id + "-dim2"]: e.target.value,
            })
          }
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />{" "}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <input
          type="text"
          name="dim3"
          id="dim3"
          autoComplete="dim3"
          placeholder="inch"
          onChange={(e) =>
            props.setShipData({
              ...props.shipData,
              [props.id + "-dim3"]: e.target.value,
            })
          }
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </td>
      <td></td>
    </tr>
  );
}

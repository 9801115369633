import { React } from "react";
import Navbar from "../Components/Navbar";
import Topbar from "../Components/Topbar";
import { useLayoutEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const reports = [
  {
    id: 5,
    name: "Inventory List",
    slug: "/reports/inventory-list",
    summary:
      "View all available inventory with quantities, each item status, etc.",
  },
  {
    id: 1,
    name: "Inventory Usage",
    slug: "/reports/inventory-usage",
    summary:
      "Usage summary by item code within product group. Report details include last quantity received, amount used year-to-date, amount used in previous year, date last used, average used per month for last 6 months balance-on-hand, number of open orders.",
  },
  {
    id: 2,
    name: "Inventory Usage by Territory",
    slug: "/reports/inventory-usage-by-territory",
    summary:
      "Usage and item cost summary by territory. Report details include item-code, item description, quantity used, and item cost within a selected date range.",
  },
  {
    id: 3,
    name: "Item Detail by Territory",
    slug: "/reports/item-detail-by-territory",
    summary:
      "Run for selected item-code, lists order details by Territory for items shipped within a selected date range. Order details include order number, order type, name/address, quantity, order date, and ship date.",
  },
  {
    id: 4,
    name: "Low and Out Report **",
    slug: "/reports/low-and-out-report",
    summary:
      "This report show items that have reached their lowpoint. Lowpoints are usually set to 20% of inventory. Inventory levels reaching the lowpoint may indicate that inventory needs to be reordered.",
  },

  {
    id: 6,
    name: "Search Prior Orders **",
    slug: "/reports/search-prior-orders",
    summary:
      "Provides search capabilities to inquire prior orders placed through out the ordering web site. Lists the order number, status, shipping information and tracking numbers if applicable.",
  },
  {
    id: 7,
    name: "Maintain Pending Orders **",
    slug: "/reports/maintain-pending-orders",
    summary:
      "Provides functionality to search, review modify and release / delete pending orders.",
  },
];

function Reports() {
  return (
    <>
      <div className="flex flex-row w-full">
        <Navbar />

        <div className="flex flex-col w-4/5 bg-gray-100">
          <Topbar />

          {/* content */}
          <ul
            role="list"
            className="divide-y divide-gray-100 p-12 m-4 bg-white"
          >
            {reports.map((report) => (
              <Link to={report.slug}>
                <li
                  key={report.id}
                  className="flex items-center justify-between gap-x-6 py-5"
                  
                >
                  <div className="min-w-0">
                    <div className="flex items-start gap-x-3">
                      <p className="text-base font-semibold leading-6 text-gray-900">
                        {report.name}
                      </p>
                    </div>
                    <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                      <p>{report.summary}</p>
                    </div>
                  </div>
                  {/* <div className="flex flex-none items-center gap-x-4">
                  <Link to={report.slug}>
                    <div className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">
                      View report
                    </div>
                  </Link>
                </div> */}
                </li>
              </Link>
            ))}
          </ul>
          {/* content */}
        </div>
      </div>
    </>
  );
}

export default Reports;

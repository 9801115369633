import axios from "axios";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { redirect } from "react-router-dom";

const endpoint = "https://mikroapp.testwebspace.co.uk";
const token = Cookies.get("token");

export const useGetRequest = (path) => {
  const [status, setStatus] = useState("idle");
  const [data, setData] = useState([]);

  const auth = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      setStatus("fetching");
      const res = await axios.get(`${endpoint}/${path}`, auth);
      if (res) {
        if (res.data.status === "not_logged_in") {
          return redirect("/");
        }
        setData(res.data);
      }
      setStatus("fetched");
    };

    fetchData();
  }, [path]);

  return { status, data };
};

export const sendPostRequest = async (path, params) => {

  const auth = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.post(`${endpoint}/${path}`, params, auth);

  console.log(res.data);

  if (res.data.status === "not_logged_in") {
    return redirect("/");
  }

  return res.data;
};

export const sendPostRequestMultipart = async (path, params) => {

  const auth = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data"
    },
  };

  const formData = new FormData();
  for (const [key, value] of Object.entries(params)) {
    formData.append(key, value);  
  }

  const res = await axios.post(`${endpoint}/${path}`, formData, auth);

  if (res.data.status === "not_logged_in") {
    return redirect("/");
  }

  return res.data;
};

export const sendGetRequest = async (path, params) => {

  const auth = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.get(`${endpoint}/${path}`, auth);

  if (res.data.status === "not_logged_in") {
    return redirect("/");
  }

  return res.data;

};


// export const sendPostRequestMultipart = async (path, params) => {

//   const auth = {
//     headers: {
//       Authorization: `Bearer ${token}`,
//       headers: { "Content-Type": "multipart/form-data" }
//     },
//   };

//   const res = await axios.post(`${endpoint}/${path}`, params, auth);

//   console.log(res.data);

//   if (res.data.status === "not_logged_in") {
//     return redirect("/");
//   }

//   return res.data;
// };

import Navbar from "../Components/Navbar";
import Topbar from "../Components/Topbar";
import { useLayoutEffect, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { sendPostRequest, useGetRequest } from "../Client";
import { React, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { dateFormatter } from "../config";
import Swal from "sweetalert2";

function ReceivingPrevious() {
  const [receivedList, setReceivedList] = useState([]);

  const receivingDelete = async (id) => {
    const res = await sendPostRequest("receiving/delete", {
      stockMovementID: id,
    });

    setTimeout(() => {
      window.location.reload(false);
    }, 500);
  };

  // confirm delete
  const areYouSure = (id) => {
    Swal.fire({
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#de3333",
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("delete", id);
        receivingDelete(id);
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  const { status, data } = useGetRequest("receiving/previous", {});

  if (status == "fetched") {
    return (
      <>
        <div className="flex flex-row">
          <Navbar />

          <div className="flex flex-col w-4/5 bg-gray-100">
            <Topbar />

            <div className="bg-white px-4 sm:px-6 lg:px-8 border border-gray-200 ml-10 mr-10 mt-10 mb-60">
              {/* <form onSubmit={handleSubmit}> */}
              <div className="space-y-12">
                <div className="pb-5 mt-8">
                  <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto flex flex-row">
                      <h1 className="text-2xl font-semibold leading-6 text-gray-900">
                        Previous Receiving Shipments
                      </h1>
                    </div>
                  </div>
                  <div className="mt-8 flow-root mb-5">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="w-1/6 py-3.5 pl-4 pr-3 text-left text-sm font-medium text-gray-900 sm:pl-0"
                              >
                                Date
                              </th>

                              <th
                                scope="col"
                                className="w-1/6 px-3 py-3.5 text-left text-sm font-medium text-gray-900"
                              >
                                Customer
                              </th>

                              <th
                                scope="col"
                                className="w-1/4 px-3 py-3.5 text-left text-sm font-medium text-gray-900"
                              >
                                Inventory Item
                              </th>

                              <th
                                scope="col"
                                className="w-1/6 px-3 py-3.5 text-left text-sm font-medium text-gray-900"
                              >
                                Quantity
                              </th>

                              <th
                                scope="col"
                                className="w-1/6 px-3 py-3.5 text-left text-sm font-medium text-gray-900"
                              >
                                Skids
                              </th>

                              <th
                                scope="col"
                                className="w-1/6 px-3 py-3.5 text-left text-sm font-medium text-gray-900"
                              ></th>
                            </tr>
                          </thead>

                          {data?.previous.length > 0 ? (
                            <tbody className="divide-y divide-gray-200">
                              {data?.previous.map((item, key) => (
                                <tr>
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                    {dateFormatter(item.receivingDate)}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {item.customerName}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {item.description}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {item.quantity}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {item.skids}
                                  </td>
                                  <td className="block text-right">
                                    <span className="mt-2 isolate inline-flex rounded-md shadow-sm">
                                      <button
                                        type="button"
                                        onClick={() =>
                                          areYouSure(item.stockMovementID)
                                        }
                                        className="relative -ml-px inline-flex items-center rounded-l-md rounded-r-md bg-red-500 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-red-400 focus:z-10"
                                      >
                                        Delete
                                      </button>
                                    </span>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          ) : (
                            <>
                              <tbody className="divide-y divide-gray-200">
                                <tr>
                                  <td
                                    colSpan="5"
                                    className="w-full text-center whitespace-nowrap px-3 py-4 text-sm text-gray-500 col-span-6"
                                  >
                                    -- No shipments found --
                                  </td>
                                </tr>
                              </tbody>
                            </>
                          )}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* </form> */}
            </div>
            {/* content */}
          </div>
        </div>
      </>
    );
  }
}

export default ReceivingPrevious;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from "./Pages/login";
import Dashboard from "./Pages/Dashboard";
import Order from "./Pages/Order";
import Customers from "./Pages/Customers";
import Users from "./Pages/AdminUsers";
import CustomerEdit from "./Pages/CustomerEdit";
import CustomerInventory from "./Pages/CustomerInventory";
import Categories from "./Pages/Categories";
import Divisions from "./Pages/Divisions";
import CustomerCategories from "./Pages/CustomerCategories";
import CustomerRates from "./Pages/CustomerRates";
import CustomerUsers from "./Pages/CustomerUsers";
import CustomerProcessing from "./Pages/customer-processing";
import CustomerSettings from "./Pages/CustomerSettings";
import CustomerCarriers from "./Pages/CustomerCarriers";
import CustomerCarrierServices from "./Pages/CustomerCarrierServices";
import CustomerCarrierSettings from "./Pages/CustomerCarrierSettings";
import OrderSearch from "./Pages/order-search";
import Reports from "./Pages/reports";
import Billing from "./Pages/billing";
import Billed from "./Pages/billed";
import Receiving from "./Pages/receiving";
import ReceivingPrevious from "./Pages/receiving-previous";
import ChargeTypes from "./Pages/charge-types";
import Stock from "./Pages/Stock";
import Locations from "./Pages/locations";
import ReportsInventoryUsage from "./Pages/Reports/InventoryUsage";
import ReportsInventoryList from "./Pages/Reports/InventoryList";
import ReportsInventoryUsageTerritory from "./Pages/Reports/InventoryUsageTerritory";
import ReportsItemDetailTerritory from "./Pages/Reports/ItemDetailTerritory";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/order/:orderID",
    element: <Order />,
  },
  {
    path: "/customers",
    element: <Customers />,
  },
  {
    path: "/users",
    element: <Users />,
  },
  {
    path: "/customer/edit/:customerID",
    element: <CustomerEdit />,
  },
  {
    path: "/customer/inventory/:customerID",
    element: <CustomerInventory />,
  },
  {
    path: "/categories/:customerID",
    element: <Categories />,
  },
  {
    path: "/divisions/:customerID",
    element: <Divisions />,
  },
  {
    path: "/customer/categories/:customerID/:divisionID",
    element: <CustomerCategories />,
  },
  {
    path: "/customer/users/:customerID",
    element: <CustomerUsers />,
  },
  {
    path: "/customer/rates/:customerID",
    element: <CustomerRates />,
  },
  {
    path: "/customer/processing/:customerID",
    element: <CustomerProcessing />,
  },
  {
    path: "/customer/settings/:customerID",
    element: <CustomerSettings />,
  },
  {
    path: "/customer/carriers/:customerID",
    element: <CustomerCarriers />,
  },
  {
    path: "/stock/:customerID/:inventoryID",
    element: <Stock />,
  },
  {
    path: "/order-search",
    element: <OrderSearch />,
  },
  {
    path: "/reports",
    element: <Reports />,
  },
  {
    path: "/billing",
    element: <Billing />,
  },
  {
    path: "/billed",
    element: <Billed />,
  },
  {
    path: "/receiving",
    element: <Receiving />,
  },
  {
    path: "/receiving-previous",
    element: <ReceivingPrevious />,
  },
  {
    path: "/charge-types",
    element: <ChargeTypes />,
  },
  {
    path: "/customer/carriers/services/:customerID/:carrierID",
    element: <CustomerCarrierServices />,
  },
  {
    path: "/customer/carriers/settings/:customerID/:carrierID",
    element: <CustomerCarrierSettings />,
  },
  {
    path: "/reports/inventory-usage",
    element: <ReportsInventoryUsage />,
  },
  {
    path: "/reports/inventory-list",
    element: <ReportsInventoryList />,
  },
  {
    path: "/reports/inventory-usage-by-territory",
    element: <ReportsInventoryUsageTerritory />,
  },
  {
    path: "/reports/item-detail-by-territory",
    element: <ReportsItemDetailTerritory />,
  },
  {
    path: "/locations",
    element: <Locations />,
  },
]);

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <QueryClientProvider client={queryClient}>
    <RouterProvider router={router} />
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

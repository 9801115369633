import { React } from "react";
import Navbar from "../Components/Navbar";
import Topbar from "../Components/Topbar";
import { useLayoutEffect, useRef, useState } from "react";
import { sendPostRequest, useGetRequest } from "../Client";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function ChargeTypes() {
  const [inputs, setInputs] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const requestBody = {
      chargeTypeName: inputs.chargeTypeName,
      defaultCharge: inputs.defaultCharge
    };

    setTimeout(() => {
      window.location.reload(false);
    }, 500);

    const res = await sendPostRequest("charges/add", requestBody);

    console.log('res', res)
  };


  const { status: statusChargeTypes, data: dataChargeTypes } = useGetRequest(
    "charges/list",
    {}
  );

  if (
    statusChargeTypes == "fetched"
  ) {
    return (
      <>
        <div className="flex flex-row w-full">
          <Navbar />

          <div className="flex flex-col w-4/5 bg-gray-100">
            <Topbar />

            {/* content */}
            <div className="bg-white px-4 sm:px-6 lg:px-8 border border-gray-200 ml-10 mr-10 mt-10 mb-10">
              <form onSubmit={handleSubmit}>
                <div className="border px-2 py-2 mt-5">
                  <div className="w-full flex flex-row mt-4 justify-start align-end">
                    
                    <div className="w-1/2">
                      <div className="mb-5 mx-2">
                        <label
                          htmlFor="company"
                          className="block text-sm font-medium text-gray-900 mb-3"
                        >
                          Charge Type Name
                        </label>
                        <div className="">
                          <input
                            type="text"
                            name="chargeTypeName"
                            onChange={handleChange}
                            required="required"
                            value={inputs.chargeTypeName || ""}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="w-1/2">
                      <div className="mb-5 mx-2">
                        <label
                          htmlFor="company"
                          className="block text-sm font-medium text-gray-900 mb-3"
                        >
                          Default Charge ($)
                        </label>
                        <div className="">
                          <input
                            type="number"
                            name="defaultCharge"
                            onChange={handleChange}
                            required="required"
                            value={inputs.defaultCharge || ""}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                    </div>


                  </div>

                  <div className="w-full grid justify-items-end">
                    <button
                      type="submit"
                      className="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Add Charge Type
                    </button>
                  </div>
                </div>
              </form>

              <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <div className="relative">
                      <table className="min-w-full table-fixed divide-y divide-gray-300">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="w-1/7 py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                            >
                              Charge Type Name
                            </th>
                            <th
                              scope="col"
                              className="w-1/7 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Default Charge
                            </th>
                            
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {dataChargeTypes.list.map((list) => (
                            <tr>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {list.chargeName}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                ${list.defaultRate}
                              </td>

                              {/* <td className="block text-right">
                                <span className="mt-2 isolate inline-flex rounded-md shadow-sm">
                                  <button
                                    type="button"
                                    onClick={() =>
                                      billingDelete(list.customerChargeID)
                                    }
                                    className="relative -ml-px inline-flex items-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-red-400 focus:z-10"
                                  >
                                    Delete
                                  </button>
                                </span>
                              </td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* content */}
          </div>
        </div>
      </>
    );
  }
}

export default ChargeTypes;

import { React, Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useMutation } from "@tanstack/react-query";
import { sendPostRequest, useGetRequest } from "../../Client";

export default function CustomerCategoryAdd(props) {
  let customerID = props.customerID;

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  // adding new
  const [categoryName, setCategoryName] = useState();
  const [categoryLevel, setCategoryLevel] = useState(0);
  const [inputs, setInputs] = useState({});

  // parent dropdown
  const { status: statusParent, data: dataParent } = useGetRequest(
    "categories/parent/" + props.divisionID + "/" + (categoryLevel-1),
    {}
  );

  // adding inventory
  const inventoryAdd = async (path, body) => {
    const res = await sendPostRequest(path, body);
    props.addedNotyf();
  };

  const { mutateAsync: doAddMutation } = useMutation({
    mutationFn: (data) => {
      return inventoryAdd(data.path, { customerID, ...data.body });
    },
    onSuccess: () => {
      props.queryClient.invalidateQueries({ queryKey: ["customerCategories"] });
    },
  });

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={props.setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900"></Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                            onClick={() => props.setOpen(false)}
                          ></button>
                        </div>
                      </div>
                      <table className="min-w-full divide-y divide-gray-300 mt-10">
                        <tbody className="divide-gray-200">
                          <tr>
                            <td>
                              {" "}
                              <label
                                htmlFor="categoryName"
                                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                              >
                                Category Name
                              </label>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <input
                                type="text"
                                name="categoryName"
                                id="categoryName"
                                onChange={(e) => {
                                  setCategoryName(e.target.value);
                                }}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              {" "}
                              <label
                                htmlFor="categoryLevel"
                                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                              >
                                Category Level
                              </label>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <select
                                id="categoryLevel"
                                name="categoryLevel"
                                onChange={(e) =>
                                  setCategoryLevel(e.target.value)
                                }
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                              >
                                <option value="0">Please Select</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                              </select>
                            </td>
                          </tr>

                          {dataParent.status !==
                          "success" ? null : categoryLevel > 1 ? (
                            <tr>
                              <td>
                                {" "}
                                <label
                                  htmlFor="region"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                                >
                                  Parent Category
                                </label>
                              </td>
                              <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                                <select
                                  id="parentCategory"
                                  name="parentCategory"
                                  onChange={handleChange}
                                  required="required"
                                  value={inputs.parentCategory || ""}
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                >
                                  <option></option>
                                  {dataParent.categoriesParent?.map((item) => (
                                    <option value={item.categoryID}>
                                      {item.categoryName}
                                    </option>
                                  ))}
                                </select>
                              </td>
                            </tr>
                          ) : null}
                        </tbody>
                      </table>
                    </div>

                    <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                      <div className="mt-6">
                        <a
                          href="#"
                          onClick={async () => {
                            try {
                              await doAddMutation({
                                path: "customer/category/add",
                                body: {
                                  categoryName: categoryName,
                                  categoryLevel: categoryLevel,
                                  customerID: props.customerID,
                                  divisionID: props.divisionID,
                                  parentCategory: inputs.parentCategory,
                                },
                              });
                              props.setOpen(false);
                            } catch (e) {
                              console.log(e);
                            }
                          }}
                          className="flex items-center justify-center rounded-md border border-transparent bg-green-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-green-700"
                        >
                          Add Item
                        </a>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

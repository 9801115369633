import { React, Fragment, useState, Alert } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { PlusIcon, MinusIcon } from "@heroicons/react/20/solid";
import CloseRow from "./closeRow";

export default function Close(props) {
  const [closeCartons, setCloseCartons] = useState(1);

  return (
    <Transition.Root show={props.closeOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={props.setCloseOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          Enter weights and dimensions
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                            onClick={() => props.setCloseOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            {/* <XMarkIcon className="h-6 w-6" aria-hidden="true" /> */}
                          </button>
                        </div>
                      </div>

                      <table className="min-w-full divide-y divide-gray-300 mt-10">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="w-1/5 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                            >
                              Weight
                            </th>
                            <th
                              scope="col"
                              className="w-1/5 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Dim 1
                            </th>
                            <th
                              scope="col"
                              className="w-1/5 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Dim 2
                            </th>
                            <th
                              scope="col"
                              className="w-1/5 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Dim 3
                            </th>
                            <th
                              scope="col"
                              className="w-1/5 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="flex flex-row">
                                <button
                                  type="button"
                                  onClick={() =>
                                    setCloseCartons(closeCartons + 1)
                                  }
                                  className="rounded-full bg-sky-600 p-1 text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                                >
                                  <PlusIcon
                                    className="h-3 w-3"
                                    aria-hidden="true"
                                  />
                                </button>
                                <button
                                  type="button"
                                  onClick={() =>
                                    setCloseCartons(
                                      closeCartons > 1
                                        ? closeCartons - 1
                                        : closeCartons - 0
                                    )
                                  }
                                  className="border-1 ml-1 rounded-full bg-sky-600 p-1 text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                                >
                                  <MinusIcon
                                    className="h-3 w-3"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                          {[...Array(closeCartons)].map((page, i) => (
                            <CloseRow
                              id={i}
                              closeData={props.closeData}
                              setCloseData={props.setCloseData}
                            />
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                      <div className="mt-6">
                        <a
                          href="#"
                          onClick={() => {
                            props.closeOrder(props.closeData, closeCartons);
                            props.setCloseOpen(false);
                          }}
                          className="flex items-center justify-center rounded-md border border-transparent bg-sky-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-sky-700"
                        >
                          Close Order
                        </a>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

import { React, Fragment, useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Topbar from "../../Components/Topbar";
import { Dialog, Transition } from "@headlessui/react";
import { useParams } from "react-router-dom";
import { sendGetRequest, sendPostRequest, useGetRequest } from "../../Client";
import ReactLoading from "react-loading";
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
import Swal from "sweetalert2";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import UsersAdd from "./add";
import UsersUpdate from "./update";
import CustomersBreadcrumb from "../../Components/CustomersBreadcrumb";

function Users() {
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);

  const queryClient = useQueryClient();

  const [userID, setUserID] = useState();

  // confirm delete
  const areYouSure = (id) => {
    Swal.fire({
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#de3333",
    }).then((result) => {
      if (result.isConfirmed) {
        doDeleteMutation(id);
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  // confirmation notifications
  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
  });

  const updatedNotyf = () => {
    notyf.success("User updated");
  };

  const addedNotyf = () => {
    notyf.success("User added");
  };

  const deletedNotyf = () => {
    notyf.success({ message: "User deleted", background: "#FF0000" });
  };

  const errorNotyf = () => {
    notyf.success({ message: "Email already being used", background: "#FF0000" });
  };

  // loading user
  const { data: users, isLoading } = useQuery({
    queryFn: () => sendGetRequest("users/" + 0, {}),
    queryKey: ["users"],
  });

  // deleting user
  const userDelete = async (id) => {
    const res = await sendPostRequest("user/delete", {
      userID: id,
    });
  };

  const { mutateAsync: doDeleteMutation } = useMutation({
    mutationFn: (id) => {
      return userDelete(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
      deletedNotyf();
    },
  });

  return (
    <>
      <div className="flex flex-row w-full">
        <Navbar />

        <div className="flex flex-col w-4/5 bg-gray-100">
          <Topbar />

          <CustomersBreadcrumb />

          <div className="bg-white px-4 sm:px-6 lg:px-8 border border-gray-200 ml-10 mr-10 mt-10 mb-40">
            <form>
              <div className="space-y-12">
                <div className="mt-10">
                  <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                      <h1 className="text-2xl font-semibold leading-6 text-gray-900 mb-5">
                        Admin Users
                      </h1>
                    </div>
                    <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                      <button
                        type="button"
                        onClick={() => setOpen(true)}
                        className="block rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                      >
                        Add User
                      </button>
                    </div>
                  </div>
                  <div className="mt-8 flow-root mb-10">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-medium text-gray-900 sm:pl-0"
                              >
                                First Name
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-medium text-gray-900"
                              >
                                Last Name
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-medium text-gray-900"
                              >
                                Email
                              </th>

                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-medium text-gray-900"
                              ></th>
                            </tr>
                          </thead>
                          {!isLoading ? (
                            users.users?.length > 0 ? (
                              <tbody className="divide-y divide-gray-200">
                                {users.users?.map((item) => (
                                  <tr key={item.userID}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-700 sm:pl-0">
                                      {item.firstName}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      {item.lastName}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      {item.email}
                                    </td>

                                    <td className="block text-right">
                                      <span className="mt-2 isolate inline-flex rounded-md shadow-sm">
                                        <button
                                          type="button"
                                          onClick={() => {
                                            setUserID(item.userID);
                                            setEditOpen(true);
                                          }}
                                          className="relative inline-flex items-center rounded-l-md bg-amber-500 hover:bg-amber-400 focus-visible:outline-amber-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 focus:z-10"
                                        >
                                          Edit
                                        </button>

                                        <button
                                          type="button"
                                          onClick={() => {
                                            if (areYouSure(item.userID)) {
                                              userDelete(item.userID);
                                            }
                                          }}
                                          className="relative -ml-px inline-flex items-center rounded-r-md bg-red-500 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-red-400 focus:z-10"
                                        >
                                          Delete
                                        </button>
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            ) : (
                              <>
                                <tbody className="divide-y divide-gray-200">
                                  <tr>
                                    <td
                                      colSpan="4"
                                      className="w-full text-center whitespace-nowrap px-3 py-4 text-sm text-gray-500 col-span-6"
                                    >
                                      -- No users found --
                                    </td>
                                  </tr>
                                </tbody>
                              </>
                            )
                          ) : (
                            <>
                              <tbody className="divide-y divide-gray-200">
                                <tr>
                                  <td
                                    colSpan="5"
                                    className="w-full text-center whitespace-nowrap px-3 py-4 text-sm text-gray-500 col-span-6"
                                  >
                                    <ReactLoading
                                      type={"spin"}
                                      color="#5046e5"
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </>
                          )}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <UsersAdd
        open={open}
        setOpen={setOpen}
        addedNotyf={addedNotyf}
        errorNotyf={errorNotyf}
        customerID={0}
        queryClient={queryClient}
      />

      {userID && (
        <UsersUpdate
          editOpen={editOpen}
          setEditOpen={setEditOpen}
          updatedNotyf={updatedNotyf}
          errorNotyf={errorNotyf}
          customerID={0}
          queryClient={queryClient}
          userID={userID}
        />
      )}
    </>
  );
}

export default Users;

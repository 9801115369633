import { Fragment, useState, React } from "react";
import { Disclosure } from "@headlessui/react";
import {
  ArrowTrendingUpIcon,
  BanknotesIcon,
  ChartBarIcon,
  ChevronRightIcon,
  CurrencyDollarIcon,
  TruckIcon,
} from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  BellIcon,
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Navbar() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState(true);

  const navigation = [
    {
      name: "Dashboard",
      href: "/dashboard",
      icon: HomeIcon,
      current: currentTab == "dashboard" ? true : false,
    },
    {
      name: "Search Orders",
      href: "/order-search",
      icon: MagnifyingGlassIcon,
      current: currentTab == "order-search" ? true : false,
    },
    {
      name: "Customers",
      href: "/customers",
      icon: TruckIcon,
      current: currentTab == "customers" ? true : false,
    },
    {
      name: "Users",
      href: "/users",
      icon: UsersIcon,
      current: currentTab == "users" ? true : false,
    },
    {
      name: "Receiving",
      href: "/receiving",
      icon: TruckIcon,
      current: currentTab == "receiving" ? true : false,
    },
    {
      name: "Reports",
      href: "/reports",
      icon: ArrowTrendingUpIcon,
      current: currentTab == "reports" ? true : false,
    },
    {
      name: "Billing",
      href: "/billing",
      icon: BanknotesIcon,
      current: currentTab == "billing" ? true : false,
    },
    {
      name: "Charge Types",
      href: "/charge-types",
      icon: CurrencyDollarIcon,
      current: currentTab == "charge-types" ? true : false,
    },
  ];

  return (
    <>
      <div className="flex flex-col w-1/5">
        {/* Static sidebar for desktop */}
        {/* <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col"> */}
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4">
          <div className="flex h-16 shrink-0 items-center">
            <img
              className="h-8 w-auto"
              src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
              alt="Your Company"
            />
          </div>
          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="-mx-2 space-y-1">
                  {navigation.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className={classNames(
                          item.current
                            ? "bg-gray-800 text-white"
                            : "text-gray-400 hover:text-white hover:bg-gray-800",
                          "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                        )}
                      >
                        <item.icon
                          className="h-6 w-6 shrink-0"
                          aria-hidden="true"
                        />
                        {item.name}
                      </a>
                    </li>
                  ))}
                  <div className="text-white text-xs ml-4">v1.0</div>
                </ul>
                <ul className="pb-80"></ul>
              </li>
            </ul>
          </nav>
        </div>
        {/* </div> */}
      </div>
    </>
  );
}

export default Navbar;

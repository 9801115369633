import { React, Fragment, useState, useEffect, Alert } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { sendPostRequest } from "../../Client";
import { useMutation } from "@tanstack/react-query";

export default function UsersAdd(props) {
  // adding new
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  // adding inventory
  const userAdd = async (path, body) => {
    const res = await sendPostRequest(path, body);
    props.addedNotyf();
  };

  const { mutateAsync: doAddMutation } = useMutation({
    mutationFn: (data) => {
      return userAdd(data.path, { ...data.body });
    },
    onSuccess: () => {
      props.queryClient.invalidateQueries({ queryKey: ["users"] });
    },
  });

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={props.setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900"></Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                            onClick={() => props.setOpen(false)}
                          ></button>
                        </div>
                      </div>

                      <table className="min-w-full divide-y divide-gray-300 mt-10">
                        <tbody className="divide-gray-200">
                          <tr>
                            <td>
                              {" "}
                              <label
                                htmlFor="firstName"
                                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                              >
                                First Name
                              </label>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <input
                                type="text"
                                name="firstName"
                                id="firstName"
                                onChange={(e) => {
                                  setFirstName(e.target.value);
                                }}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <label
                                htmlFor="lastName"
                                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                              >
                                Last Name
                              </label>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <input
                                type="text"
                                name="lastName"
                                id="lastName"
                                onChange={(e) => {
                                  setLastName(e.target.value);
                                }}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              {" "}
                              <label
                                htmlFor="lastName"
                                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                              >
                                Email
                              </label>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <input
                                type="text"
                                name="email"
                                id="email"
                                onChange={(e) => {
                                  setEmail(e.target.value);
                                }}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              {" "}
                              <label
                                htmlFor="lastName"
                                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                              >
                                Password
                              </label>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <input
                                type="password"
                                name="password"
                                id="password"
                                onChange={(e) => {
                                  setPassword(e.target.value);
                                }}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                      <div className="mt-6">
                        <a
                          href="#"
                          onClick={async () => {

                            if (!firstName) {
                              alert("Please enter a first name");
                              return;
                            }

                            if (!lastName) {
                              alert("Please enter a last name");
                              return;
                            }

                            if (!email) {
                              alert("Please enter an email address");
                              return;
                            }

                            if (!password) {
                              alert("Please enter a password");
                              return;
                            }

                            try {
                              const res = await doAddMutation({
                                path: "users/add",
                                body: {
                                  firstName: firstName,
                                  lastName: lastName,
                                  email: email,
                                  password: password,
                                  customerID: 0,
                                  isAdmin: 1,
                                },
                              });
                              props.setOpen(false);
                            } catch (e) {
                              props.errorNotyf();
                              console.log(e);
                            }
                          }}
                          className="flex items-center justify-center rounded-md border border-transparent bg-green-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-green-700"
                        >
                          Add User
                        </a>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

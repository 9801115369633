import { React } from "react";
import Navbar from "../Components/Navbar";
import Topbar from "../Components/Topbar";
import { Link, useLocation } from "react-router-dom";
import { sendGetRequest } from "../Client";
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
import { useQuery, useQueryClient } from "@tanstack/react-query";

function Customers() {
  const queryClient = useQueryClient();

  // loading inventory
  const { data: customers, isLoading } = useQuery({
    queryFn: () => sendGetRequest("customers", {}),
    queryKey: ["customers"],
  });

  const { state } = useLocation();

  // confirmation notifications
  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
  });

  const deletedNotyf = () => {
    notyf.success("Customer deleted");
  };

  if (customers?.customers?.length > 0) {
    return (
      <div className="flex flex-row w-full">
        <Navbar />

        <div className="flex flex-col w-4/5 bg-gray-100">
          <Topbar />

          {/* content */}
          <div className="bg-white px-4 sm:px-6 lg:px-8 border border-gray-200 ml-10 mr-10 mt-10 mb-60">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h1 className="text-2xl font-semibold leading-6 text-gray-900 mt-10">
                  Customers
                </h1>
              </div>
            </div>
            <div className="mt-8 flow-root mb-10">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <div className="relative">
                    <table className="min-w-full table-fixed divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="w-1/7 py-3.5 pr-3 text-left text-sm font-medium text-gray-900"
                          >
                            Customer Name
                          </th>
                          <th
                            scope="col"
                            className="w-1/7 px-3 py-3.5 text-left text-sm font-medium text-gray-900"
                          >
                            Contact Name
                          </th>
                          <th
                            scope="col"
                            className="w-1/7 px-3 py-3.5 text-left text-sm font-medium text-gray-900"
                          >
                            Contact Email
                          </th>
                          <th
                            scope="col"
                            className="w-1/7 px-3 py-3.5 text-left text-sm font-medium text-gray-900"
                          >
                            Contact Number
                          </th>
                          <th
                            scope="col"
                            className="w-1/7 px-3 py-3.5 text-left text-sm font-medium text-gray-900"
                          ></th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {customers?.customers?.map((customer) => (
                          <tr key={customer.customerID}>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {customer.customerName}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {customer.contactName}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {customer.contactEmail}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {customer.contactNumber}
                            </td>

                            <td className="block text-right">
                              <span className="mt-2 isolate inline-flex rounded-md shadow-sm">
                                <Link
                                  to={`/customer/edit/${customer.customerID}`}
                                >
                                  <button
                                    type="button"
                                    className="relative inline-flex items-center rounded-l-md bg-amber-500 hover:bg-amber-400 focus-visible:outline-amber-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 focus:z-10"
                                  >
                                    Edit
                                  </button>
                                </Link>

                                <Link
                                  to={`/customer/rates/${customer.customerID}`}
                                >
                                  <button
                                    type="button"
                                    className="relative -ml-px inline-flex items-center bg-gray-500 hover:bg-gray-400 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 focus:z-10"
                                  >
                                    Rates
                                  </button>
                                </Link>

                                <Link to={`/customer/processing/${customer.customerID}`}>
                                  <button
                                    type="button"
                                    className="relative -ml-px inline-flex items-center bg-purple-400 hover:bg-purple-500 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 focus:z-10"
                                  >
                                    Processing
                                  </button>
                                </Link>

                                <Link
                                  to={`/customer/carriers/${customer.customerID}`}
                                >
                                  <button
                                    type="button"
                                    className="relative -ml-px inline-flex items-center bg-slate-400 hover:bg-slate-500 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 focus:z-10"
                                  >
                                    Carriers
                                  </button>
                                </Link>

                                <Link
                                  to={`/customer/settings/${customer.customerID}`}
                                >
                                  <button
                                    type="button"
                                    className="relative -ml-px inline-flex items-center bg-red-400 hover:bg-red-500 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 focus:z-10"
                                  >
                                    Settings
                                  </button>
                                </Link>

                                <Link
                                  to={`/customer/inventory/${customer.customerID}`}
                                >
                                  <button
                                    type="button"
                                    className="relative -ml-px inline-flex items-center bg-blue-500 hover:bg-blue-400 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 focus:z-10"
                                  >
                                    Inventory
                                  </button>
                                </Link>

                                <Link
                                  to={`/customer/users/${customer.customerID}`}
                                >
                                  <button
                                    type="button"
                                    className="relative -ml-px inline-flex items-center rounded-r-md bg-green-500 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-green-400 focus:z-10"
                                  >
                                    Users
                                  </button>
                                </Link>
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* content */}
        </div>
      </div>
    );
  }
}

export default Customers;

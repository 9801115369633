import "notyf/notyf.min.css"; // for React, Vue and Svelte

function CustomersName(props) {
  return (
    <div className="flex justify-center text-2xl font-bold">{props.customerName}</div>
  );
}

export default CustomersName;

